<template>
  <div>
    <h2 class="main-title" id="mainTitle">{{ pageTitle }}</h2>
    <div class="ordinary-container">
      <vs-progress id="progressBar1" :percent="100 - 3 * secondsTillReload" :indeterminate="indeterminateMode"
        color="primary">primary</vs-progress>
      <br><br>
      <div class="filter-panel">
        <vs-button id="modeButton" color="success" type="relief" @click="onModeChanged">
          {{ day_mode ? "День" : "Часы" }}
        </vs-button>
        <vs-button type="relief" @click="onExportButtonClicked">Excel</vs-button>
        <vs-button v-if="targetPlatform == 'Sber'" type="relief" color="Olive" @click="onSourceButtonClicked">{{
          this.apiSource }}</vs-button>


        <vs-button class="help-button" color="white" type="line" @click="onHelpButtonClick">Помощь</vs-button>
      </div>


      <vs-table class="custom-main-grid" id="mainGrid" ref="mainGrid" :data="dataSource" @selected="handleSelected"
        pagination max-items="50">
        <template slot="thead">
          <vs-th sort-key="current_rank" id="rankCol">
            Ранк
          </vs-th>
          <vs-th sort-key="rank_diff" id="rankDiffCol">
            Изменение ранка
          </vs-th>
          <vs-th sort-key="z_score" id="zCol">
            Z крит.
          </vs-th>
          <vs-th sort-key="text">
            NER
          </vs-th>
          <vs-th sort-key="count_now" id="countCol">
            Количество (шт.)
          </vs-th>
          <vs-th sort-key="count_diff" id="countDiffCol">
            Изменение (шт.)
          </vs-th>
          <vs-th id="msgCol">
            Пример сообщения
          </vs-th>
          <vs-th id="btnsCol">
            Команды
          </vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].current_rank">
              {{ data[indextr].current_rank }}
            </vs-td>
            <vs-td v-if="data[indextr].rank_diff > 0" :data="data[indextr].rank_diff">
              <span class="text-success">{{ data[indextr].rank_diff }}</span>
            </vs-td>
            <vs-td v-else-if="data[indextr].rank_diff < 0" :data="data[indextr].rank_diff">
              <span class="text-danger">{{ data[indextr].rank_diff }}</span>
            </vs-td>
            <vs-td v-else>
              <span class="text-secondary">-</span>
            </vs-td>
            <vs-td :data="data[indextr].z_score">
              {{ (data[indextr].is_z_not_empty) ? data[indextr].z_score.toFixed(1) : "not calculated" }}
            </vs-td>
            <vs-td :data="data[indextr].text">
              <div class="ner-block">
                <h4> {{ data[indextr].text }} </h4>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].count_now">
              {{ data[indextr].count_now }}
            </vs-td>
            <vs-td v-if="data[indextr].count_diff > 0" :data="data[indextr].count_diff">
              <span class="text-success">{{ data[indextr].count_diff }}</span>
            </vs-td>
            <vs-td v-else-if="data[indextr].count_diff < 0" :data="data[indextr].count_diff">
              <span class="text-danger">{{ data[indextr].count_diff }}</span>
            </vs-td>
            <vs-td v-else>
              <span class="text-secondary">-</span>
            </vs-td>
            <vs-td :data="data[indextr].msg">
              <div class="message-block">
                {{ ((typeof data[indextr].msg === "string") ?
                  (data[indextr].msg.slice(0, Math.min(data[indextr].msg.length, 350)) +
                    (data[indextr].msg.length > 350 ? '...' : '')) : data[indextr].msg) }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].msg">
              <div class="button-block">
                <vs-button type="relief" color="#FF5733"
                  @click="(e) => { onReportButtonClicked(e, data[indextr].entity_id, 'report-show'); return false; }">R</vs-button>
                <vs-button type="relief" color="#95A5A6"
                  @click="(e) => { onReportButtonClicked(e, data[indextr].entity_id, 'report-hide'); return false; }">H</vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup style="color:rgb(255,255,255)" background-color-popup="rgb(40,61,85)" fullscreen title="Доп. информация"
        :active.sync="popupVisible">
        <div>Выбранная строка - {{ selectedNer }}</div>
        <vs-table :data="sourceMessages">
          <template slot="thead">
            <vs-th>
              Сообщения
            </vs-th>
            <vs-th>
              URL
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].text">
                <div>
                  <text-highlight :queries="[selectedNer]">
                    {{ data[indextr].text }}
                  </text-highlight>
                </div>
              </vs-td>
              <vs-td :data="data[indextr].link">
                <div>
                  <a :href="data[indextr].link" target="_blank">URL</a>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
    </div>
    <v-tour name="runtimeViewTour" :steps="tourSteps" :options="tourOptions">
    </v-tour>
  </div>
</template>

<script>
import XlsxExportEngine from "../../helpers/xlsxExportEngine"


export default {
  name: "sml_analytics",
  components: {
  },
  data() {
    return {
      reportButtonClicked: false,
      apiSource: "Macro",
      pageTitle: "Ключевые объекты в реальном времени",
      targetPlatform: process.env.VUE_APP_TARGET_PLATFORM,
      tourSteps: [{
        target: "#progressBar1",
        header: {
          title: "Добро пожаловать!",
        },
        content: "Сейчас вы находитесь на вкладке отcлеживания отслеживания объектов в реальном времени"
      }, {
        target: "#progressBar1",
        header: {
          title: "Добро пожаловать!",
        },
        content: "Каждый раз когда полоска сверху заполняется, мы загружаем свежие данные"
      }, {
        target: '#countCol',
        header: {
          title: "Что значат эти цифры?",
        },
        content: "В этой колонке вы видете количество упоминаний определённого слова/словосочетания за последние 24 часа"
      }, {
        target: '#rankCol',
        header: {
          title: "Что значат эти цифры?",
        },
        content: "Все слова/словосочетания ранжируются по количеству упоминаний."
      }, {
        target: '#countDiffCol',
        header: {
          title: "Что значат эти цифры?",
        },
        content: "Динамика - это разница между количеством упоминаний за последние 24 часа и предшествующим им периодом"
      }, {
        target: '#modeButton',
        header: {
          title: "Что за 'предшествующий период'?",
        },
        content: "'День' - начинается вчера, в тоже время что и сейчас и идёт на 24ч в прошлое"
      }, {
        target: '#modeButton',
        header: {
          title: "Что за 'предшествующий период'?",
        },
        content: "'Часы' - начинается за 2 часа до текущего времени и идёт на 24ч в прошлое",
      }, {
        target: '#modeButton',
        header: {
          title: "Зачем они нужны?",
        },
        content: "Пользуйтесь режимом 'Часы' что бы отслеживать динамику внутри 2х часов, и 'День' для дневной динамики."
      }, {
        target: '#rankDiffCol',
        header: {
          title: "Что значат эти цифры?",
        },
        content: "Динамика ранга отражает, как изменилась позиция слова/словосочетания от предшествующего периода к текущему."
      }, {
        target: '#zCol',
        header: {
          title: "Что значат эти цифры?",
        },
        content: "Мы высчитываем насколько аномально текущее количество упоминаний слова/словосочетания. Значения больше 2.85 считаются аномально большими, меньше -2.85 - аномально малыми, остальные - в рамках нормы"
      }, {
        target: '#msgCol',
        header: {
          title: "Что это за текст?",
        },
        content: "В этой колонке вы можете увидеть последнее сообщение для слова/словосочетания. Кликните по строчке что бы увидеть больше примеров."
      }, {
        target: '#btnsCol',
        header: {
          title: "Что это за кнопки?",
        },
        content: "R - report / H - hide"
      }, {
        target: '#btnsCol',
        header: {
          title: "Что это за кнопки?",
        },
        content: "Если вы видите не корректную запись - ваши действия"
      }, {
        target: '#btnsCol',
        header: {
          title: "Кнопка 'R'",
        },
        content: "Запись некорректная, но осмысленный. Жмём 'Report'. Мы будем периодически их просматривать что бы разобраться."
      }, {
        target: '#btnsCol',
        header: {
          title: "Кнопка 'H'",
        },
        content: "Запись не осмысленная. Жмем 'Hide'. Она будет скрыт, на демонстрационных страницах. Мы тоже её посмотрим."
      },
      ],
      rawData: [],
      tourOptions: {
        highlight: true,
        labels: {
          buttonSkip: "Пропустить обучение",
          buttonPrevious: 'Назад',
          buttonNext: 'Дальше',
          buttonStop: 'Закончить'
        }
      },
      zMarks: {
        '100': {
          label: 'MAX',
          labelStyle: {
            left: '100%',
            margin: '0 0 0 10px',
            top: '50%',
            transform: 'translateY(-50%)'
          }
        },
        '-10': {
          label: 'MIN',
          labelStyle: {
            left: '0%',
            margin: '0 0 0 -35px',
            top: '50%',
            transform: 'translateY(-50%)'
          }
        }
      },
      minZ: -10,
      maxZ: 100,
      zRange: [-10, 100],
      countMarks: {
        '5000': {
          label: 'MAX',
          labelStyle: {
            left: '100%',
            margin: '0 0 0 10px',
            top: '50%',
            transform: 'translateY(-50%)'
          }
        },
        '5': {
          label: 'MIN',
          labelStyle: {
            left: '0%',
            margin: '0 0 0 -35px',
            top: '50%',
            transform: 'translateY(-50%)'
          }
        }
      },
      minCount: 5,
      maxCount: 5000,
      countRange: [5, 5000],
      secondsTillReload: 0,
      indeterminateMode: false,
      day_mode: true,
      dataSource: [],
      sourceMessages: [],
      popupVisible: false,
      selectedNer: ''
    }
  },

  mounted() {
    this.setupDefalutSorting()

    this.getData();
    let that = this;
    this.refreshTimer = setInterval(() => {
      if (that.secondsTillReload <= 0) {
        that.getData();
        that.secondsTillReload = 30;
      }
      else
        if (!that.indeterminateMode)
          that.secondsTillReload--;
    }, 1000);
  },
  methods: {
    filterOnLimits(i) {
      let minZCondition = (this.minZ != this.zRange[0]) ? i.z_score > this.zRange[0] : true;
      let maxZCondition = (this.maxZ != this.zRange[1]) ? i.z_score < this.zRange[1] : true;
      let minCountCondition = (this.minCount != this.countRange[0]) ? i.count_now > this.countRange[0] : true;
      let maxCountCondition = (this.maxCount != this.countRange[1]) ? i.count_now < this.countRange[1] : true;
      return minZCondition & maxZCondition & minCountCondition & maxCountCondition;
    },
    onHelpButtonClick() {
      this.$tours["runtimeViewTour"].start()
    },
    setupDefalutSorting() {
      this.$refs.mainGrid.currentSortKey = "z_score";
      this.$refs.mainGrid.currentSortType = "desc";
    },
    onModeChanged(e) {
      this.day_mode = !this.day_mode;
      this.getData();
    },
    onExportButtonClicked(e) {
      new XlsxExportEngine().saveToXlsx(
        "realtime_table.xlsx",
        this.dataSource.map((i) => {
          return {
            current_rank: i.current_rank,
            rank_diff: i.rank_diff,
            z_score: i.z_score,
            text: i.text,
            count_now: i.count_now,
            count_diff: i.count_diff,
            message: i.msg
          }
        })
      );
    },
    handleSelected(selectedRow) {
      if (!this.reportButtonClicked) {
        this.selectedNer = selectedRow.text;
        this.loadLastSourceMessages(selectedRow.entity_id);
      }
      this.reportButtonClicked = false;
    },
    getHostname() {
      let basic_url = ''
      if (this.apiSource == "Sber") {
        basic_url = basic_url + this.$hostname_ba
      } else {
        basic_url = basic_url + this.$hostname_ba_macro
      }
      return basic_url;

    },
    loadLastSourceMessages(nerId) {
      let url = '' + '/topic-modelling-live/nerHistory/' + nerId + '/' + this.apiSource + 'BA';
      window.open(url, '_blank');
    },
    onReportButtonClicked(e, nerId, mode) {
      this.reportButtonClicked = true;
      this.axios({
        method: 'post',
        data: { targetId: nerId, mode: mode },
        url: this.getHostname() + "/realtime-ner/report-ner-issue",
      })
        .then(e => {
          let message = (mode == 'report-show')
            ? 'Данный элемент помечен как некорректный. Мы рассмотрим его что бы улучшить продукт в будующем. Спасибо.'
            : 'Данный элемент помечен как некорректный. Мы скроем его с некоторых страниц. Спасибо.';
          this.$vs.notify({
            title: 'Информация',
            text: message,
            color: "warning"
          })
        });
    },
    onSourceButtonClicked(e) {
      if (this.apiSource == "Sber") {
        this.apiSource = "Macro"
      } else {
        this.apiSource = "Sber"
      }
      this.rawData = [];
      this.dataSource = [];
      this.getData();
    },
    onZScoreFilterChanged(value, index) {
      this.dataSource = this.rawData
        .map(i => {
          return {
            entity_id: i.entity_id,
            current_rank: i.current_rank,
            rank_diff: i.rank_diff,
            z_score: (i.z_score) ? i.z_score : 2.3,
            is_z_not_empty: i.z_score != null,
            text: i.text,
            count_now: i.count_now,
            count_diff: i.count_diff,
            msg: i.msg
          }
        })
        .filter(this.filterOnLimits);
    },
    onCountScoreFilterChanged(value, index) {
      this.dataSource = this.rawData
        .map(i => {
          return {
            entity_id: i.entity_id,
            current_rank: i.current_rank,
            rank_diff: i.rank_diff,
            z_score: (i.z_score) ? i.z_score : 2.3,
            is_z_not_empty: i.z_score != null,
            text: i.text,
            count_now: i.count_now,
            count_diff: i.count_diff,
            msg: i.msg
          }
        })
        .filter(this.filterOnLimits);
    },
    getData() {
      let calc_url_ = this.getHostname() + "/realtime-ner" + ((this.day_mode) ? "/get-anomaly-ners-natasha" : "/get-anomaly-ners-natasha-2h");
      let calc_url = calc_url_ + "-dedup";
      this.indeterminateMode = true;
      this.secondsTillReload = 0;
      this.axios({
        method: 'get',
        url: calc_url,
      }).then((res) => {
        this.rawData = res.data.results;
        console.log(this.rawData);
        this.dataSource = this.rawData
          .map(i => {
            return {
              entity_id: i.entity_id,
              current_rank: i.current_rank,
              rank_diff: i.rank_diff,
              z_score: (i.z_score) ? i.z_score : 2.3,
              is_z_not_empty: i.z_score != null,
              text: i.text,
              count_now: i.count_now,
              count_diff: i.count_diff,
              msg: i.msg
            }
          })
          .filter(this.filterOnLimits);
        this.indeterminateMode = false;
        this.secondsTillReload = 30;
      }).catch(err => {
      });
    },

  },
}
</script>

<style>
.main-title {
  text-align: center
}

#z-slider-title {
  text-align: center;
  margin-top: 1vw;
}

.z-slider-box {
  left: 15.7vw;
  height: 0.5vh;
  top: 12vmin;
  width: 8.5vw;
  position: absolute;
}

#count-slider-title {
  text-align: center;
  margin-top: 1vw;
}

.count-slider-box {
  left: 30.7vw;
  height: 0.5vh;
  top: 12vmin;
  width: 8.5vw;
  position: absolute;
}

.help-button {
  right: 1vw;
  position: absolute;
}

.filter-panel {
  height: 7vh;
}

.filter-panel>* {
  display: inline !important;
}

.filter-panel>*>* {
  display: inline !important;
}

.custom-main-grid {}

.ordinary-container {
  padding: 1vw;
}

.vs-th {
  background: rgb(31 45 61);
}

.vs-con-table table {
  background: rgb(31 45 61);
}

tr {
  background: rgb(40 61 85) !important;
}

td {
  background: rgb(50 71 95);
}

.mode-switcher {
  justify-content: normal;
}

.message-block {
  word-wrap: break-word;
  max-width: 52vw;
  font-size: 1.15em;
}

.ner-block {
  word-wrap: break-word;
  max-width: 10vw;
}
</style>
